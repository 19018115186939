import axios from "axios";
// import { apiUrl } from "../config.json";

const apiUrl = process.env.API_URL;
// const tokenName = process.env.TOKEN_NAME;

export function getInquiries() {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/inquiries")
      .then(res => {
        if (res.status === 200) resolve(res.data.inquiries);
        else resolve([]);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getLatestInquiries() {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/inquiries/latest")
      .then(res => {
        if (res.status === 200) resolve(res.data.inquiries);
        else resolve([]);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function updateInquiry(id, stage) {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl + "/inquiries/update/" + id, { stage: stage })
      .then(res => {
        if (res.status === 200) resolve(true);
        else resolve(false);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function deleteInquiry(id) {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl + "/inquiries/delete/" + id)
      .then(res => {
        if (res.status === 200) resolve(true);
        else resolve(false);
      })
      .catch(err => {
        reject(err);
      });
  });
}
