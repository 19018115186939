import React, { Component } from "react";
import { Container, Table, Row, Col, Button } from "react-bootstrap";
import Moment from "react-moment";

import Paginate from "../../utils/Paginate";

import { getMessages, deleteMessage } from "../../../services/messages";
import { paginate } from "../../../tools/paginate";
import MessageViewer from "./MessageViewer";

const MESSAGES_PER_PAGE = 20;

export default class MessagesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      messages: [],
      page: 1,
      viewer: false,
      data: null
    };
  }

  componentDidMount() {
    this.checkMessages();
  }

  checkMessages() {
    getMessages()
      .then(res => {
        this.setState({
          loading: false,
          messages: res,
          page: 1
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          messages: [],
          page: 1
        });
      });
  }

  showViewer(data = null) {
    this.setState({ data: data, viewer: true });
  }

  hideViewer() {
    this.setState({ data: null, viewer: false });
  }

  handleChangePage(page) {
    this.setState({ page: page });
  }

  deleteFromList(id) {
    deleteMessage(id).then(res => this.checkMessages());
  }

  render() {
    const { loading, messages, page, viewer, data } = this.state;

    let messages_list = messages;

    const count = messages_list.length;

    let content = "";

    if (!count) {
      content = <strong>No messages found.</strong>;

      if (loading) content = <strong>Looking up messages.</strong>;
    } else {
      if (count > MESSAGES_PER_PAGE) {
        messages_list = paginate(messages_list, page, MESSAGES_PER_PAGE);
      }

      content = (
        <React.Fragment>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Email</th>
                <th>Content</th>
                <th>Sent</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {messages_list.map(message => (
                <tr key={message.id}>
                  <td>{message.fullname}</td>
                  <td>{message.email}</td>
                  <td
                    onClick={event => this.showViewer(message)}
                    style={{ cursor: "pointer" }}
                  >
                    {message.content.substring(0, 100) + "..."}
                  </td>
                  <td>
                    <Moment format="MMMM d, YYYY h:mm a">
                      {message.createdAt}
                    </Moment>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      block
                      onClick={() => this.deleteFromList(message.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Row className="my-4">
            <Col>
              <Paginate
                activePage={page}
                pageItems={count}
                pageSize={MESSAGES_PER_PAGE}
                onChangePage={page => this.handleChangePage(page)}
              />
            </Col>
          </Row>

          <MessageViewer
            show={viewer}
            onClose={() => this.hideViewer()}
            data={data}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Container fluid>
          <h1>Messages</h1>

          {content}
        </Container>
      </React.Fragment>
    );
  }
}
