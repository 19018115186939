import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { formatPhoneNumber } from "../../../tools/utils";
import { updateInquiry, deleteInquiry } from "../../../services/inquiries";

import Moment from "react-moment";

export default class Inquiry extends Component {
  handleUpdate(id, stage) {
    updateInquiry(id, stage).then(res => {
      if (res) this.props.onUpdate();
    });
  }

  handleDelete(id) {
    deleteInquiry(id).then(res => {
      if (res) this.props.onUpdate();
    });
  }

  render() {
    const {
      id,
      fullname,
      email,
      phone,
      address,
      city,
      state,
      zip,
      application,
      pH,
      packageSize,
      servingSize,
      servingsPerPackage,
      ingredients,
      regulations,
      stage,
      createdAt
    } = this.props.data;

    let current_stage = stage;

    if (stage !== "Completed") current_stage += " Stage";

    let ingredients_content = "None";

    if (ingredients.length) {
      ingredients_content = ingredients.map((ingredient, index) => (
        <div key={index}>
          {ingredient.name} ({ingredient.weight} mg)
        </div>
      ));
    }

    return (
      <React.Fragment>
        <Row className="border p-2 mt-3">
          <Col>
            <Row className="border-bottom">
              <Col md="6" className="pb-2">
                <h5>{fullname}</h5>
              </Col>
              <Col md="6" className="pb-2 text-md-right">
                <Dropdown as={ButtonGroup}>
                  <Button variant="success">{current_stage}</Button>

                  <Dropdown.Toggle
                    split
                    variant="success"
                    id="dropdown-split-basic"
                  />

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={event => this.handleUpdate(id, "Initial")}
                    >
                      Initial Stage
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={event => this.handleUpdate(id, "Formulation")}
                    >
                      Formulation Stage
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={event =>
                        this.handleUpdate(id, "Customer Feedback")
                      }
                    >
                      Customer Feedback Stage
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={event => this.handleUpdate(id, "Completed")}
                    >
                      Completed
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <span>&nbsp;&nbsp;</span>
                <Button
                  variant="danger"
                  onClick={event => this.handleDelete(id)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col md="6" className="text-muted d-none d-md-block border-right">
                <Row>
                  <Col md="3">
                    <strong>Email:</strong>
                  </Col>
                  <Col md="3">
                    <a href={`mailto:${email}`}>{email}</a>
                  </Col>
                  <Col md="3">
                    <strong>Phone:</strong>
                  </Col>
                  <Col md="3">{formatPhoneNumber(phone)}</Col>
                </Row>
                <Row>
                  <Col md="3">
                    <strong>Address:</strong>
                  </Col>
                  <Col md="9">{address}</Col>
                </Row>
                <Row>
                  <Col md="3">
                    <strong>City:</strong>
                  </Col>
                  <Col md="3">{city}</Col>
                  <Col md="3">
                    <strong>State:</strong>
                  </Col>
                  <Col md="3">{state}</Col>
                </Row>
                <Row>
                  <Col md="3">
                    <strong>Zip code:</strong>
                  </Col>
                  <Col md="3">{zip}</Col>
                  <Col md="3">
                    <strong>Sent:</strong>
                  </Col>
                  <Col md="3">
                    <Moment format="ddd, MMM D, YYYY">{createdAt}</Moment>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <Col xs="6" md="3">
                    <strong>Application:</strong>
                  </Col>
                  <Col xs="6" md="3">
                    {application}
                  </Col>
                  <Col xs="6" md="3">
                    <strong>pH:</strong>
                  </Col>
                  <Col xs="6" md="3">
                    {pH}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" md="3">
                    <strong>Package size:</strong>
                  </Col>
                  <Col xs="6" md="3">
                    {packageSize}
                  </Col>
                  <Col xs="6" md="3">
                    <strong>Serving size:</strong>
                  </Col>
                  <Col xs="6" md="3">
                    {servingSize}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" md="9">
                    <strong>Servings per package:</strong>
                  </Col>
                  <Col xs="6" md="3">
                    {servingsPerPackage}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" md="3">
                    <strong>Regulations:</strong>
                  </Col>
                  <Col xs="6" md="9">
                    {regulations.split(",").join(", ")}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" md="3">
                    <strong>Ingredients:</strong>
                  </Col>
                  <Col xs="6" md="9">
                    {ingredients_content}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Inquiry.propTypes = {
  onUpdate: PropTypes.func.isRequired
};
