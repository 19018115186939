import axios from "axios";
// import { apiUrl } from "../config.json";

const apiUrl = process.env.API_URL;
// const tokenName = process.env.TOKEN_NAME;

export function getMessages() {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/messages")
      .then(res => {
        if (res.status === 200) resolve(res.data.messages);
        else resolve([]);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getLatestMessages() {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/messages/latest")
      .then(res => {
        if (res.status === 200) resolve(res.data.messages);
        else resolve([]);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function deleteMessage(id) {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl + "/messages/delete/" + id)
      .then(res => {
        if (res.status === 200) resolve(true);
        else resolve(false);
      })
      .catch(err => {
        reject(err);
      });
  });
}
