import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { getAllSettings, updateSetting } from "../../services/settings";

export default class ConfigurationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      userPassword: "",
      emailReceiver: "",
      smtpHost: "",
      smtpPort: "",
      smtpUser: "",
      smtpPassword: "",
      pressLink: "",
      newsLink: "",
      settings: {
        userName: "",
        userPassword: "",
        emailReceiver: "",
        smtpHost: "",
        smtpPort: "",
        smtpUser: "",
        pressLink: "",
        newsLink: ""
      }
    };
  }

  componentDidMount() {
    getAllSettings()
      .then(settings => {
        this.setState({
          userName: settings.userName,
          userPassword: "",
          emailReceiver: settings.emailReceiver,
          smtpHost: settings.smtpHost,
          smtpPort: settings.smtpPort,
          smtpUser: settings.smtpUser,
          smtpPassword: settings.smtpPassword,
          pressLink: settings.pressLink,
          newsLink: settings.newsLink,
          settings: {
            userName: settings.userName,
            emailReceiver: settings.emailReceiver,
            smtpHost: settings.smtpHost,
            smtpPort: settings.smtpPort,
            smtpUser: settings.smtpUser,
            pressLink: settings.pressLink,
            newsLink: settings.newsLink
          }
        });
      })
      .catch(err => {});
  }

  handleChange(name) {
    updateSetting(name, this.state[name])
      .then(() => {})
      .catch(() => {});
  }

  render() {
    const {
      userName,
      emailReceiver,
      smtpHost,
      smtpPort,
      smtpUser,
      smtpPassword,
      pressLink,
      newsLink
    } = this.state.settings;

    return (
      <React.Fragment>
        <Container fluid>
          <h1>Configuration</h1>

          <div className="ml-2 mt-3">
            <Row>
              <Col md="6">
                <h3>Dashboard Credentials</h3>

                <div className="ml-2">
                  <Row>
                    <Col md="4">Username:</Col>
                    <Col md="4">
                      <Form.Control
                        defaultValue={userName}
                        onChange={event => {
                          this.state.userName = event.target.value;
                        }}
                      ></Form.Control>
                    </Col>
                    <Col md="4">
                      <Button
                        variant="success"
                        onClick={event => this.handleChange("userName")}
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="4">Password:</Col>
                    <Col md="4">
                      <Form.Control
                        type="password"
                        onChange={event => {
                          this.state.userPassword = event.target.value;
                        }}
                      ></Form.Control>
                    </Col>
                    <Col md="4">
                      <Button
                        variant="success"
                        onClick={event => this.handleChange("userPassword")}
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="6">
                <h3>Web Links</h3>

                <div className="ml-2">
                  <Row>
                    <Col md="4">Press:</Col>
                    <Col md="4">
                      <Form.Control
                        defaultValue={pressLink}
                        onChange={event => {
                          this.state.pressLink = event.target.value;
                        }}
                      ></Form.Control>
                    </Col>
                    <Col md="4">
                      <Button
                        variant="success"
                        onClick={event => this.handleChange("pressLink")}
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="4">News:</Col>
                    <Col md="4">
                      <Form.Control
                        defaultValue={newsLink}
                        onChange={event => {
                          this.state.newsLink = event.target.value;
                        }}
                      ></Form.Control>
                    </Col>
                    <Col md="4">
                      <Button
                        variant="success"
                        onClick={event => this.handleChange("newsLink")}
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="6">
                <h3>Email</h3>

                <div className="ml-2">
                  <Row>
                    <Col md="4">Fowarding email:</Col>
                    <Col md="4">
                      <Form.Control
                        defaultValue={emailReceiver}
                        onChange={event => {
                          this.state.emailReceiver = event.target.value;
                        }}
                      ></Form.Control>
                    </Col>
                    <Col md="4">
                      <Button
                        variant="success"
                        onClick={event => this.handleChange("emailReceiver")}
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="6">
                <h3>SMTP</h3>

                <div className="ml-2">
                  <Row>
                    <Col md="4">Host:</Col>
                    <Col md="4">
                      <Form.Control
                        defaultValue={smtpHost}
                        onChange={event => {
                          this.state.smtpHost = event.target.value;
                        }}
                      ></Form.Control>
                    </Col>
                    <Col md="4">
                      <Button
                        variant="success"
                        onClick={event => this.handleChange("smtpHost")}
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="4">Port:</Col>
                    <Col md="4">
                      <Form.Control
                        defaultValue={smtpPort}
                        onChange={event => {
                          this.state.smtpPort = event.target.value;
                        }}
                      ></Form.Control>
                    </Col>
                    <Col md="4">
                      <Button
                        variant="success"
                        onClick={event => this.handleChange("smtpPort")}
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="6">
                <h3>&nbsp;</h3>

                <div className="ml-2">
                  <Row>
                    <Col md="4">User:</Col>
                    <Col md="4">
                      <Form.Control
                        defaultValue={smtpUser}
                        onChange={event => {
                          this.state.smtpUser = event.target.value;
                        }}
                      ></Form.Control>
                    </Col>
                    <Col md="4">
                      <Button
                        variant="success"
                        onClick={event => this.handleChange("smtpUser")}
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="4">Password:</Col>
                    <Col md="4">
                      <Form.Control
                        type="password"
                        defaultValue={smtpPassword}
                        onChange={event => {
                          this.state.smtpPassword = event.target.value;
                        }}
                      ></Form.Control>
                    </Col>
                    <Col md="4">
                      <Button
                        variant="success"
                        onClick={event => this.handleChange("smtpPassword")}
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
