import axios from "axios";
// import { apiUrl } from "../config.json";

const apiUrl = process.env.API_URL;
// const tokenName = process.env.TOKEN_NAME;

export function getAllSettings() {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/settings")
      .then(res => {
        if (res.status === 200) resolve(res.data.settings);
        else resolve({});
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function updateSetting(name, new_value) {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl + "/settings/update/" + name, { value: new_value })
      .then(res => {
        if (res.status === 200) resolve(true);
        else resolve(false);
      })
      .catch(err => {
        reject(err);
      });
  });
}
