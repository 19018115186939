import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { getCurrentUser, setLogoutCallback } from "../services/auth";

import NavBar from "./layout/NavBar";
import SideBar from "./layout/SideBar";

import NotFoundPage from "./pages/NotFoundPage";
import HomePage from "./pages/home/HomePage";
import InquiriesPage from "./pages/inquiries/InquiriesPage";
import EventsPage from "./pages/events/EventsPage";
import MessagesPage from "./pages/messages/MessagesPage";
import ConfigurationPage from "./pages/ConfigurationPage";

import Login from "./utils/Login";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = { show: "d-none", loggedIn: false };
  }

  componentDidMount() {
    setLogoutCallback(() => this.handleLogout());

    if (getCurrentUser()) {
      this.setState({ loggedIn: true });
    }
  }

  handleToggleSideBar() {
    this.setState();

    this.setState(state => {
      return {
        show: state.show ? "" : "d-none"
      };
    });
  }

  handleLogout() {
    this.setState({ loggedIn: false });
  }

  handleLogin() {
    this.setState({ loggedIn: true });
  }

  render() {
    if (!this.state.loggedIn) {
      return <Login onLogin={() => this.handleLogin()} />;
    }

    return (
      <React.Fragment>
        <NavBar
          onToggleSideBar={() => this.handleToggleSideBar()}
          onLogout={() => this.handleLogout()}
        />

        <Container fluid>
          <Row>
            <Col
              md="2"
              className={`bg-light ${this.state.show} d-md-block sidebar`}
            >
              <div className="pt-2">
                <SideBar />
              </div>
            </Col>
            <Col md="10" className="ml-auto">
              <div className="pt-2">
                <Switch>
                  <Route path="/not-found" component={NotFoundPage} />
                  <Route path="/configuration" component={ConfigurationPage} />
                  <Route path="/messages" component={MessagesPage} />
                  <Route path="/events" component={EventsPage} />
                  <Route path="/inquiries" component={InquiriesPage} />
                  <Route exact path="/" component={HomePage} />
                  <Redirect to="/not-found" />
                </Switch>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
