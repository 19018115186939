import axios from "axios";
import jwtDecode from "jwt-decode";
// import { apiUrl, tokenName } from "../config.json";

const apiUrl = process.env.API_URL;
const tokenName = process.env.TOKEN_NAME;

setJwt();

let logoutCallback;

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (expectedError) {
    logout();
  }

  return Promise.reject(error);
});

export function setLogoutCallback(callback) {
  logoutCallback = callback;
}

export function setJwt() {
  axios.defaults.headers.common["Authorization"] = `Bearer ${getJwt()}`;
}

export function login(username, password) {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl + "/login", { userName: username, userPassword: password })
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem(tokenName, res.data.token);
          setJwt();
          resolve();
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        const { status, data } = err.response;

        if (status === 401) {
          reject(data.message);
        } else {
          reject("Unknown error.");
        }
      });
  });
}

export function logout() {
  logoutCallback();

  localStorage.removeItem(tokenName);
}

export function getCurrentUser() {
  try {
    return jwtDecode(localStorage.getItem(tokenName)).name;
  } catch (error) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenName);
}
