import axios from "axios";
// import { apiUrl } from "../config.json";

const apiUrl = process.env.API_URL;
// const tokenName = process.env.TOKEN_NAME;

export function createEvent(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl + "/events/create/", data)
      .then(res => {
        if (res.status === 200) resolve(true);
        else resolve(false);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getEvents() {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/events")
      .then(res => {
        if (res.status === 200) resolve(res.data.events);
        else resolve([]);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function updateEvent(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl + "/events/update/" + id, data)
      .then(res => {
        if (res.status === 200) resolve(true);
        else resolve(false);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function deleteEvent(id) {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl + "/events/delete/" + id)
      .then(res => {
        if (res.status === 200) resolve(true);
        else resolve(false);
      })
      .catch(err => {
        reject(err);
      });
  });
}
