import React, { Component } from "react";
import { Container, Form, Button, Spinner } from "react-bootstrap";
import { login } from "../../services/auth";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      checking: false,
      error: "",
      username: "",
      password: ""
    };
  }

  checkValidity(form) {
    const { username, password } = form;

    const errors = {
      username: "",
      password: ""
    };

    username.setCustomValidity("Invalid");
    password.setCustomValidity("Invalid");

    if (!username.value) {
      errors.username = "Please enter a your username.";
    } else {
      username.setCustomValidity("");
    }

    if (!password.value) {
      errors.password = "Please enter a password.";
    } else {
      password.setCustomValidity("");
    }

    this.setState({ ...errors, validated: true });
  }

  handleSubmit(event) {
    event.preventDefault();

    const form = event.target;

    this.checkValidity(form);

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const { username, password } = form;

      login(username.value, password.value)
        .then(() => {
          this.props.onLogin();
        })
        .catch(err => {
          this.setState({ error: err });
        });
    }
  }

  render() {
    const { validated, checking, error, username, password } = this.state;

    let error_content = "";
    let status_content = "Login";

    if (error) {
      error_content = (
        <div className="mt-4 text-center text-danger">{error}</div>
      );
    }

    if (checking) {
      status_content = <Spinner animation="grow" />;
    }

    return (
      <React.Fragment>
        <Container className="vh-100 text-center d-flex justify-content-center align-items-center">
          <Form
            noValidate
            validated={validated}
            onSubmit={event => this.handleSubmit(event)}
            className="shadow-lg p-5"
          >
            <h1 className="h3 mb-4 font-weight-normal">Please log in</h1>
            <Form.Control name="username" placeholder="Username" required />
            <Form.Control.Feedback type="invalid">
              {username}
            </Form.Control.Feedback>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              required
            />
            <Form.Control.Feedback type="invalid">
              {password}
            </Form.Control.Feedback>
            {error_content}
            <Button
              variant="success"
              type="submit"
              block
              disabled={checking}
              className="mt-4"
            >
              {status_content}
            </Button>
          </Form>
        </Container>
      </React.Fragment>
    );
  }
}
