import React, { Component } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import Moment from "react-moment";

import Paginate from "../../utils/Paginate";
import EventEditor from "./EventEditor";

import { getEvents, deleteEvent } from "../../../services/events";
import { paginate } from "../../../tools/paginate";

const EVENTS_PER_PAGE = 20;

export default class EventsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      events: [],
      page: 1,
      editor: false,
      data: null
    };
  }

  componentDidMount() {
    this.checkEvents();
  }

  checkEvents() {
    getEvents()
      .then(res => {
        this.setState({
          loading: false,
          events: res,
          page: 1
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          events: [],
          page: 1
        });
      });
  }

  showEventEditor(data = null) {
    this.setState({ data: data, editor: true });
  }

  hideEventEditor(update = false) {
    this.setState({ data: null, editor: false }, () => {
      if (update) {
        this.checkEvents();
      }
    });
  }

  handleChangePage(page) {
    this.setState({ page: page });
  }

  deleteEventFromList(id) {
    deleteEvent(id).then(res => this.checkEvents());
  }

  render() {
    const { loading, events, page, editor, data } = this.state;

    let events_list = events;

    const count = events.length;

    let content = "";

    if (!count) {
      content = "No events found.";

      if (loading) content = "Looking up events.";
    } else if (count > EVENTS_PER_PAGE) {
      events_list = paginate(events_list, page, EVENTS_PER_PAGE);
    }

    return (
      <React.Fragment>
        <Container fluid>
          <h1>Events</h1>

          <Row className="mb-2">
            <Col className="text-right">
              <Button variant="info" onClick={() => this.showEventEditor()}>
                Create New Event
              </Button>
            </Col>
          </Row>

          {!count && <strong>{content}</strong>}

          {count > 0 && (
            <React.Fragment>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Subtitle</th>
                    <th>Starting</th>
                    <th>Ending</th>
                    <th>Address</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {events_list.map(event => (
                    <tr key={event.id}>
                      <td>{event.title}</td>
                      <td>{event.subtitle}</td>
                      <td>
                        <Moment format="MMMM d, YYYY h:mm a">
                          {event.start}
                        </Moment>
                      </td>
                      <td>
                        <Moment format="MMMM d, YYYY h:mm a">
                          {event.end}
                        </Moment>
                      </td>
                      <td>{event.address}</td>
                      <td>
                        <Row>
                          <Col md="6">
                            <Button
                              variant="primary"
                              size="sm"
                              block
                              onClick={() => this.showEventEditor(event)}
                            >
                              Edit
                            </Button>
                          </Col>
                          <Col md="6">
                            <Button
                              variant="danger"
                              size="sm"
                              block
                              onClick={() => this.deleteEventFromList(event.id)}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Row className="my-4">
                <Col>
                  <Paginate
                    activePage={page}
                    pageItems={count}
                    pageSize={EVENTS_PER_PAGE}
                    onChangePage={page => this.handleChangePage(page)}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Container>

        <EventEditor
          show={editor}
          data={data}
          onClose={update => this.hideEventEditor(update)}
        />
      </React.Fragment>
    );
  }
}
