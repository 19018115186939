import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Button } from "react-bootstrap";

import { logout } from "../../services/auth";

export default class NavBar extends Component {
  handleLogout() {
    logout();
  }

  render() {
    return (
      <Navbar
        expand="md"
        bg="dark"
        variant="dark"
        className="text-uppercase"
        sticky="top"
      >
        <Navbar.Brand as={NavLink} exact to="/">
          Virun
        </Navbar.Brand>
        <Button
          variant="outline-secondary"
          onClick={() => this.props.onToggleSideBar()}
          className="d-inline d-md-none"
        >
          <span className="navbar-toggler-icon"></span>
        </Button>
        <Nav className="ml-auto">
          <Button variant="dark" onClick={() => this.handleLogout()}>
            Logout
          </Button>
        </Nav>
      </Navbar>
    );
  }
}
