import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Row, Col, Image } from "react-bootstrap";
import { createEvent, updateEvent } from "../../../services/events";
// import { apiUrl } from "../../../config.json";

const apiUrl = process.env.API_URL;

import bsCustomFileInput from "bs-custom-file-input";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default class EventEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      error: "",
      img: null,
      img_required: true,
      title: "",
      subtitle: "",
      start: null,
      end: null,
      address: "",
      link: ""
    };
  }

  componentDidMount() {
    bsCustomFileInput.init();
  }

  componentDidUpdate(prev_props) {
    if (this.props.data !== null && this.props.data !== prev_props.data) {
      const { data } = this.props;

      if (data) {
        const {
          image,
          title,
          subtitle,
          start,
          end,
          address,
          link
        } = this.props.data;

        this.setState({
          img: apiUrl + image,
          img_required: false,
          title: title,
          subtitle: subtitle,
          start: new Date(start),
          end: new Date(end),
          address: address,
          link: link
        });
      } else {
        this.setState({
          img: null,
          img_required: true,
          title: "",
          subtitle: "",
          start: null,
          end: null,
          address: "",
          link: ""
        });
      }
    }
  }

  showImage(event) {
    this.setState({
      img: URL.createObjectURL(event.target.files[0])
    });
  }

  setStartDate(date) {
    this.setState({ start: date });
  }

  setEndDate(date) {
    this.setState({ end: date });
  }

  handleClose(update = false) {
    this.setState(
      {
        validated: false,
        error: "",
        img: null,
        img_required: true,
        title: "",
        subtitle: "",
        start: null,
        end: null,
        address: "",
        link: ""
      },
      this.props.onClose(update)
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ validated: true });

    if (event.target.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const { data } = this.props;
      const { start, end } = this.state;

      const form = new FormData(event.target);

      form.append("start", start);
      form.append("end", end);

      if (!data) {
        createEvent(form)
          .then(res => {
            if (res) {
              this.handleClose(true);
            } else {
              this.setState({
                error: "Could not create event, please try again later."
              });
            }
          })
          .catch(err => {
            this.setState({
              error: "Unexpected error occured while trying to create event."
            });
          });
      } else {
        updateEvent(data.id, form)
          .then(res => {
            if (res) {
              this.handleClose(true);
            } else {
              this.setState({
                error: "Could not update event, please try again later."
              });
            }
          })
          .catch(err => {
            this.setState({
              error: "Unexpected error occured while trying to update event."
            });
          });
      }
    }
  }

  render() {
    const {
      validated,
      error,
      img,
      img_required,
      title,
      subtitle,
      start,
      end,
      address,
      link
    } = this.state;
    const { show, data } = this.props;

    let modal_title = "Create Event";
    let button = "Create";

    if (data) {
      modal_title = "Edit Event";
      button = "Save Changes";
    }

    let img_content = (
      <span className="text-muted">Choose the event image.</span>
    );

    if (img) {
      img_content = <Image src={img} alt="Event image" fluid />;
    }

    return (
      <Modal show={show} onHide={() => this.handleClose()} centered>
        <Form
          noValidate
          validated={validated}
          onSubmit={event => this.handleSubmit(event)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{modal_title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-4">
              <Col md="6" className="mx-auto">
                {img_content}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <div className="custom-file">
                  <Form.Label htmlFor="image" className="custom-file-label">
                    Choose the event image
                  </Form.Label>
                  <Form.Control
                    id="image"
                    type="file"
                    name="image"
                    className="custom-file-input form-control"
                    accept="image/*"
                    onChange={event => this.showImage(event)}
                    required={img_required}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose an image.
                  </Form.Control.Feedback>
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Event title"
                  name="title"
                  defaultValue={title}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a title.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Event subtitle"
                  name="subtitle"
                  defaultValue={subtitle}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <DatePicker
                  style={{ width: "100%" }}
                  className="form-control"
                  selected={start}
                  onChange={date => this.setStartDate(date)}
                  showTimeSelect
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Start date and time"
                  required
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <DatePicker
                  style={{ width: "100%" }}
                  className="form-control"
                  selected={end}
                  onChange={date => this.setEndDate(date)}
                  showTimeSelect
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="End date and time"
                  required
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Event address"
                  name="address"
                  defaultValue={address}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a address.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Event information link"
                  name="link"
                  defaultValue={link}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-danger">{error}</Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={event => this.handleClose()}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {button}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

EventEditor.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.any
};
