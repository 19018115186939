import React, { Component } from "react";

export default class NotFoundPage extends Component {
  render() {
    return (
      <React.Fragment>
        <strong>The content you are looking for was not found.</strong>
      </React.Fragment>
    );
  }
}
