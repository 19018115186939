import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  ButtonGroup,
  Button
} from "react-bootstrap";

import Inquiry from "./Inquiry";
import Paginate from "../../utils/Paginate";

import { getInquiries } from "../../../services/inquiries";
import { paginate } from "../../../tools/paginate";

const INQURIES_PER_PAGE = 4;

export default class InquiriesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      inquiries: [],
      page: 1,
      show: "All",
      filter: ""
    };
  }

  componentDidMount() {
    this.checkInquiries();
  }

  checkInquiries() {
    getInquiries()
      .then(res => {
        this.setState({
          loading: false,
          inquiries: res,
          page: 1
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          inquiries: [],
          page: 1
        });
      });
  }

  handleChangePage(page) {
    this.setState({ page: page });
  }

  filter(stage) {
    this.setState({ filter: stage });
  }

  render() {
    const { loading, inquiries, page, show, filter } = this.state;

    let list_inquiries = inquiries;

    if (filter !== "") {
      list_inquiries = inquiries.filter(inquiry => inquiry.stage === filter);
    }

    const inquiries_count = list_inquiries.length;

    if (inquiries_count === 0 && filter === "") {
      let content = "No inquiries found.";

      if (loading) content = "Looking up inquiries.";

      return (
        <Container fluid>
          <h1>Inquiries</h1>
          <p className="ml-2 mt-3">
            <strong>{content}</strong>
          </p>
        </Container>
      );
    } else if (inquiries_count > INQURIES_PER_PAGE) {
      list_inquiries = paginate(list_inquiries, page, INQURIES_PER_PAGE);
    }

    return (
      <Container fluid>
        <h1>Inquiries</h1>
        <Row>
          <Col>
            <Dropdown as={ButtonGroup}>
              <Button variant="info">Show {show}</Button>

              <Dropdown.Toggle split variant="info" id="dropdown-split-basic" />

              <Dropdown.Menu>
                <Dropdown.Item onClick={event => this.filter("")}>
                  Show All
                </Dropdown.Item>
                <Dropdown.Item onClick={event => this.filter("Initial")}>
                  Show Only On Initial Stage
                </Dropdown.Item>
                <Dropdown.Item onClick={event => this.filter("Formulation")}>
                  Show Only On Formulation Stage
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={event => this.filter("Customer Feedback")}
                >
                  Show Only On Customer Feedback Stage
                </Dropdown.Item>
                <Dropdown.Item onClick={event => this.filter("Completed")}>
                  Show Only Completed
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col className="text-right">
            <Button variant="dark" onClick={event => this.checkInquiries()}>
              Refresh List
            </Button>
          </Col>
        </Row>
        {list_inquiries.map(inquiry => (
          <Inquiry
            key={inquiry.id}
            data={inquiry}
            onUpdate={() => this.checkInquiries()}
          />
        ))}

        {inquiries_count > 0 && (
          <Row className="my-4">
            <Col>
              <Paginate
                activePage={page}
                pageItems={inquiries_count}
                pageSize={INQURIES_PER_PAGE}
                onChangePage={page => this.handleChangePage(page)}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}
