import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Button, Table } from "react-bootstrap";
import Moment from "react-moment";
import { getLatestInquiries } from "../../../services/inquiries";
import { getLatestMessages } from "../../../services/messages";

export default class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inquiries: [],
      messages: []
    };
  }

  componentDidMount() {
    getLatestInquiries()
      .then(inquiries => {
        this.setState({ inquiries: inquiries });
      })
      .catch(err => {});

    getLatestMessages()
      .then(messages => {
        this.setState({ messages: messages });
      })
      .catch(err => {});
  }

  render() {
    const { inquiries, messages } = this.state;

    let inquiries_content = <Card.Text>No inquiries were found.</Card.Text>;
    let messages_content = <Card.Text>No messages were found.</Card.Text>;

    if (inquiries.length > 0) {
      inquiries_content = (
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Application</th>
              <th>Sent</th>
            </tr>
          </thead>
          <tbody>
            {inquiries.map(inquiry => (
              <tr key={inquiry.id}>
                <td>{inquiry.fullname}</td>
                <td>{inquiry.application}</td>
                <td>
                  <Moment format="ddd, MMM D, YYYY">{inquiry.createdAt}</Moment>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }

    if (messages.length > 0) {
      messages_content = (
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Sent</th>
            </tr>
          </thead>
          <tbody>
            {messages.map(messages => (
              <tr key={messages.id}>
                <td>{messages.fullname}</td>
                <td>{messages.email}</td>
                <td>
                  <Moment format="ddd, MMM D, YYYY">
                    {messages.createdAt}
                  </Moment>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }

    return (
      <React.Fragment>
        <Container fluid>
          <h1>Dashboard</h1>

          <Row className="ml-2 my-3">
            <Col md="6">
              <Card>
                <Card.Header>Latest Inquiries</Card.Header>
                <Card.Body>{inquiries_content}</Card.Body>
                <Card.Footer className="text-right">
                  <Button
                    as={Link}
                    to="/inquiries"
                    variant="light"
                    className="text-uppercase"
                  >
                    Manage
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <Card.Header>Latest Messages</Card.Header>
                <Card.Body>{messages_content} </Card.Body>
                <Card.Footer className="text-right text-uppercase">
                  <Button
                    as={Link}
                    to="/messages"
                    variant="light"
                    className="text-uppercase"
                  >
                    Manage
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
