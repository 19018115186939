import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col } from "react-bootstrap";
import Moment from "react-moment";

export default class MessageViewer extends Component {
  render() {
    const { show, onClose, data } = this.props;

    if (data == null) return <React.Fragment></React.Fragment>;

    return (
      <Modal show={show} onHide={onClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="6">{data.fullname}</Col>
            <Col md="6">{data.email}</Col>
          </Row>
          <Row>
            <Col>
              <Moment format="MMMM d, YYYY h:mm a">{data.createdAt}</Moment>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="ml-2">{data.content}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

MessageViewer.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.any
};
