import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";

export default class SideBar extends Component {
  render() {
    return (
      <Nav className="flex-column">
        <Nav.Link as={NavLink} exact to="/">
          Dashboard
        </Nav.Link>
        <Nav.Link as={NavLink} to="/inquiries">
          Inquiries
        </Nav.Link>
        <Nav.Link as={NavLink} to="/events">
          Events
        </Nav.Link>
        <Nav.Link as={NavLink} to="/messages">
          Messages
        </Nav.Link>
        <Nav.Link as={NavLink} to="/configuration">
          Configuration
        </Nav.Link>
      </Nav>
    );
  }
}
