import React, { Component } from "react";
import { Pagination } from "react-bootstrap";

export default class Paginate extends Component {
  render() {
    const { activePage, pageItems, pageSize, onChangePage } = this.props;
    const total_pages = Math.ceil(pageItems / pageSize);

    if (total_pages === 1) return null;

    const pages = Array(total_pages).fill("page");

    return (
      <Pagination>
        <Pagination.First onClick={() => onChangePage(1)} />
        {pages.map((page, index) => {
          const page_number = index + 1;

          return (
            <Pagination.Item
              key={page_number}
              onClick={() => onChangePage(page_number)}
              active={page_number === activePage}
            >
              {page_number}
            </Pagination.Item>
          );
        })}
        <Pagination.Last onClick={() => onChangePage(total_pages)} />
      </Pagination>
    );
  }
}
